import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Button from '../../Core/Button'
import Text from '../../Core/Text'
import Input from '../../Core/Input'
import * as styles from '../../../templates/styles/residence.module.scss'
import {
  selectSfResidencesList,
  sfResidencesInit,
} from '../../../state/salesforceResidences'
import { useSelector } from 'react-redux'
import api, { PopinFormState } from '../../../utils/api'
import { useIntl } from '../../../../plugins/gatsby-plugin-intl-kley'
import useGtmEvent from '../../../hooks/useGtmEvent'
import ModalLegalText from '../../ModalLegalText'
import { useTranslation } from '../../../hooks/useTranslation'
import SelectBox from '../../Core/SelectBox'
import Select from '../../Core/Select'

const FromResidenceMultiple = (
  props: {
    data: any
    className?: string
    formInfo: any
  },
  ref: any
) => {
  const { data, className = '', formInfo} = props
  const { t } = useTranslation('common')
  const [currentStep, setCurrentStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [residenceValue, setResidenceValue] = useState("nul")
  const [residenceList, setResidenceList] = useState(data.linkedResidences)
  const [formState, setFormState] = useState<PopinFormState>({
    typeDemande: 'Louer un logement sur plusieurs mois ou années',
  })
  const [hasError, setHasError] = useState(false)
  const sfResidences: any[] = useSelector(selectSfResidencesList)
  const sfResidencesId = sfResidences.filter(
    (residence) => residence.Residence__c === data.salesforceName
  )
  const titles = [data.step1Title, data.step2Title, data.step3Title]
  const ctas = [data.step1CTA, data.step2Cta, data.step3Cta]
  const { locale, formatMessage } = useIntl()
  const [formWasSubmitted, setFormWasSubmitted] = useState(false)


  const handleChangeField = (fieldName: string, fieldVal: any) => {
    setFormState(
      Object.assign({}, formState, {
        [fieldName]: fieldVal,
      })
    )
  }

  const handleNextStep = (e: any) => {
    e.preventDefault()
    setCurrentStep((prevStep) => prevStep + 1)
    const $container = document.getElementById('landing-form')
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setHasError(false)
    let formData: PopinFormState = formState
    console.log("form", formData, formData.residenceSelected )
    
    const sfResidencesId = sfResidences.filter(
      (residence) =>residence.AD_ResidenceId__c === formData.residenceSelected
    )
    console.log("sfResidencesId", sfResidencesId)
    if (sfResidencesId.length > 0) {
      const foundResidence = sfResidencesId[0]
      formData = Object.assign({}, formData, {
        residence: {
          id: foundResidence.AD_ResidenceId__c,
          name: foundResidence.Residence__c,
          filialeCode: foundResidence.FilialeCode__c,
        },
      })
    } else if (
      data?.linkedResidence?.salesforceName &&
      data?.linkedResidence?.salesforceId
    ) {
      formData = Object.assign({}, formData, {
        residence: {
          id: data?.linkedResidence?.salesforceId,
          name: data?.linkedResidence?.salesforceName,
        },
      })
    }
    formData = Object.assign({}, formData, {
      gclientId: '',
      gclid: '',
      gtrackingId: '',
      formOrigin: "B2C-Demande d'hébergement ou de service",
      natureDemandeur: 'Particulier',
      language: locale === 'fr' ? 'Français' : 'Anglais',
    })

    // Do not send TypedeDemande__c for B2B form
    delete formData.serviceToContact
    console.log("---formData", formData)
    setIsLoading(true)
    api
      .createLead(formData)
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data) {
          if ((res.status === 200 || res.status === 201) && res.data.success) {
            setFormState({})
            setFormWasSubmitted(true)
            setCurrentStep(3)
            toast.success(t('success sent form','common'), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
          } else {
            throw res
          }
        }
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        setHasError(true)
        toast.success(t('popin.Error message','common'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        console.log('Error creating salesforce lead:--', e.status, e.data)
      })
  }

  const FormWithImage = () => {
    return (
      <section ref={ref} id={'landing-form'} className={`form ${className}`}>
        <div className={`container form ${className} mt-10 md:mt-0 lg:grid lg:grid-cols-12`}>
          <form
            method="post"
            onSubmit={currentStep === 3 ? handleSubmit : handleNextStep}
            className={'max-w-[1131px] col-span-4 col-start-2'}
          >
            <div
              className={`px-10 lg:px-0 ${
                currentStep === 1 ? 'justify-between' : ''
              }`}
            >
              <div>
                <Text tag="p" as="h3" className={`c-bleu-fonce max-w-[395px]`}>
                  { formInfo.title }
                </Text>
                <Text as="bodyH1" className="c-bleu-fonce mt-5 max-w-[491px]">
                  { formInfo.description }
                </Text>
              </div>
              <div className='max-w-[491px]'>
                <div
                  className={`max-w-[328px] ${
                    currentStep === 1 ? '' : 'text-center max-w-[626px]'
                  } mt-6 lg:mt-0`}
                >
                  <div
                    className={`max-w-[286px] step-items flex items-center mt-10 ${
                      currentStep === 1 ? '' : 'justify-center'
                    } `}
                  >
                    {Array.from({ length: 3 }, (_, i) => {
                      const stepNumber = i + 1
                      return (
                        <React.Fragment key={`step-${i}`}>
                          <Text
                            as="captionH3"
                            tag={'div'}
                            className={`step-item rounded-full w-9 h-9 flex items-center justify-center border font-bold ${
                              currentStep === stepNumber
                                ? 'bg-typo-title border-[#F5AAA2]'
                                : 'border-[#21534F]'
                            }`}
                          >
                            0{stepNumber}
                          </Text>
                          {(i === 0 || i === 1) && (
                            <span
                              className={
                                'grow max-w-[122px] border-b border-[#21534F] mx-4'
                              }
                            ></span>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {titles[currentStep - 1] && (
                    <Text as="h6" className="c-bleu-fonce max-w-[328px] mt-6 lg:mt-14 text-left">
                      {titles[currentStep - 1]}
                    </Text>
                  )}
                  <div className={'mt-6 max-w-[328px]'}>
                    {sfResidencesId && sfResidencesId[0] && (
                      <input
                        type="hidden"
                        value={sfResidencesId[0].Id}
                        name="residence"
                      />
                    )}
                    {currentStep === 1 && (
                      <>
                      <Select
                      hideLabel={true}
                          label={
                            t(
                              'choose residence',
                              'common'
                            ) as string
                          }
                          name="residenceSelected"
                          options={
                            residenceList.length > 0
                              ? residenceList.map((c) => ({
                                  value: c.salesforceId,
                                  label: c.title,
                                }))
                              : []
                          }
                          defaultOptionPlaceholder={
                            t('residence', 'common')+" *" as string
                          }
                          required
                          onChange={handleChangeField}
                        />
                      </>
                      
                    )}
                    {currentStep === 2 && (
                      <>
                        <div className="lg:grid grid-cols-2 gap-4">
                          <Input
                            label={`${t('b2b name', 'common')} *`}
                            name="firstName"
                            required
                            maxLength={40}
                            onChange={handleChangeField}
                          />
                          <Input
                            label={`${t('b2b lastname', 'common')} *`}
                            name="lastName"
                            required
                            maxLength={80}
                            onChange={handleChangeField}
                          />
                        </div>
                      </>
                      
                    )}
                    {currentStep === 3 && (
                      <>
                      <div>
                        <Input
                          label={`${t('b2b email', 'common')} *`}
                          name="email"
                          type={'email'}
                          required
                          onChange={handleChangeField}
                        />
                        <Input
                          label={`${t('b2b number', 'common')} *`}
                          name="phone"
                          type={'tel'}
                          required
                          onChange={handleChangeField}
                        />
                      </div>
                      <Text as="bodyH1" className="c-bleu-fonce mt-5 text-left">
                        Vous allez recevoir prochainement la brochure avec toutes les
                        informations sur la résidence, nos logements et nos services.
                      </Text>
                      </>
                    )}
                  </div>
                  {currentStep !== 4 && (
                    <div>
                      <div className="mt-10 flex justify-start">
                        <Button btnType="dark" loading={isLoading}>{ctas[currentStep - 1]}</Button>
                      </div>
                    </div>
                  )}
                </div>
              <div className="mt-20">
                <ModalLegalText />
              </div>
              </div>
            </div>
          </form>
          { formInfo.image && <div className="col-span-6 col-start-6">
            <img
              src={formInfo.image.media._url}
              alt="paris"
            />
          </div> }
        </div>
    </section>
    )
  }

  const FormWithoutImage = () => {
    return (
      <section ref={ref} className={`form ${className}`}>
        <div className='container mt-10 md:mt-0 lg:grid lg:grid-cols-12 bg-[#FCF4E8] !py-12 md:!py-36 '>
          <div className={' col-span-4 col-start-2 px-3 md:px-0'}>
            <Text tag="p" as="h3" className={`c-bleu-fonce max-w-[395px]`}>
              { formInfo.title }
            </Text>
            <Text as="bodyH1" className="c-bleu-fonce mt-5 max-w-[491px]">
              { formInfo.description }
            </Text>
          </div>
          <form
          className="col-span-6 col-start-7 max-w-[1131px]"
            method="post"
            onSubmit={currentStep === 3 ? handleSubmit : handleNextStep}
          >
            <div
              className={`px-10 lg:px-0 ${
                currentStep === 1 ? 'justify-between' : ''
              }`}
            >
              <div className='max-w-[491px]'>
                <div
                  className={`max-w-[328px] ${
                    currentStep === 1 ? '' : 'text-center max-w-[626px]'
                  }`}
                >
                  <div
                    className={`max-w-[286px] step-items flex items-center mt-5 md:mt-0 ${
                      currentStep === 1 ? '' : 'justify-center'
                    } `}
                  >
                    {Array.from({ length: 3 }, (_, i) => {
                      const stepNumber = i + 1
                      return (
                        <React.Fragment key={`step-${i}`}>
                          <Text
                            as="captionH3"
                            tag={'div'}
                            className={`step-item rounded-full w-9 h-9 flex items-center justify-center border font-bold ${
                              currentStep === stepNumber
                                ? 'bg-typo-title border-[#F5AAA2]'
                                : 'border-[#21534F]'
                            }`}
                          >
                            0{stepNumber}
                          </Text>
                          {(i === 0 || i === 1) && (
                            <span
                              className={
                                'grow max-w-[122px] border-b border-[#21534F] mx-4'
                              }
                            ></span>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {titles[currentStep - 1] && (
                    <Text as="h6" className="c-bleu-fonce max-w-[328px] mt-6 lg:mt-[51px] text-left">
                      {titles[currentStep - 1]}
                    </Text>
                  )}
                  <div className={'mt-6 max-w-[328px]'}>
                    {sfResidencesId && sfResidencesId[0] && (
                      <input
                        type="hidden"
                        value={sfResidencesId[0].Id}
                        name="residence"
                      />
                    )}
                    {currentStep === 1 && (
                      <>
                      <Select
                      hideLabel={true}
                      noBackground={true}
                          label={
                            t(
                              'choose residence',
                              'common'
                            ) as string 
                          }
                          name="residenceSelected"
                          options={
                            residenceList.length > 0
                              ? residenceList.map((c) => ({
                                  value: c.salesforceId,
                                  label: c.title,
                                }))
                              : []
                          }
                          defaultOptionPlaceholder={
                            t('residence', 'common')+" *" as string
                          }
                          required
                          onChange={handleChangeField}
                        />
                      </>
                      
                    )}
                    {currentStep === 2 && (
                      <>
                        <div className="lg:grid grid-cols-2 gap-4">
                          <Input
                            label={`${t('b2b name', 'common')} *`}
                            name="firstName"
                            required
                            maxLength={40}
                            onChange={handleChangeField}
                          />
                          <Input
                            label={`${t('b2b lastname', 'common')} *`}
                            name="lastName"
                            required
                            maxLength={80}
                            onChange={handleChangeField}
                          />
                        </div>
                      </>
                      
                    )}
                    {currentStep === 3 && (
                      <>
                      <div>
                        <Input
                          label={`${t('b2b email', 'common')} *`}
                          name="email"
                          type={'email'}
                          required
                          onChange={handleChangeField}
                        />
                        <Input
                          label={`${t('b2b number', 'common')} *`}
                          name="phone"
                          type={'tel'}
                          required
                          onChange={handleChangeField}
                        />
                      </div>
                      <Text as="bodyH1" className="c-bleu-fonce mt-5 text-left">
                        { data.step3Description && data.step3Description != '' ? data.step3Description : t('success sent form', 'common')}
                      </Text>
                      </>
                    )}
                  </div>
                  {currentStep !== 4 && (
                    <div>
                      <div className="mt-10 flex justify-start">
                        <Button btnType="dark">{ctas[currentStep - 1]}</Button>
                      </div>
                    </div>
                  )}
                </div>
              <div className="mt-11">
                <ModalLegalText />
              </div>
              </div>
            </div>
            <ToastContainer />
          </form>
        </div>
    </section>
    )
  }

  if(formInfo.image){
    return FormWithImage()
  }else{
    return FormWithoutImage()
  }
}

export default React.forwardRef(FromResidenceMultiple)

import React, { useEffect, useRef, useState } from 'react'
import * as styles from './cartService.module.scss'
import image from '../../../images/cart-service.png'
import Text from '../../Core/Text'
import KLink from '../../Core/KLink'
import { GatsbyImage } from 'gatsby-plugin-image'
import replaceHife from '../../../utils/replaceHife'

export interface CartServiceItemProps {
  title: string
  subtitle: string
  ctaTitle: string,
  ctaLink: any,
  thumbnail: {
    src: string
    gatsbyImageData: any
  },
  customId?: string
}

export default function CartServiceItem(props: CartServiceItemProps) {
  const { title, subtitle, thumbnail, ctaTitle, ctaLink, customId = ''} = props

  const textRef = useRef<HTMLDivElement>(null)

  const [withLineClamp, setWithLineClamp] = useState(false)
  const [isVisibleAll, setIsVisibleAll] = useState(false)

  const handleVisibleAll = () => {
    setIsVisibleAll(!isVisibleAll)
  }

  useEffect(() => {
    if (textRef.current) {
      setWithLineClamp(
        textRef.current.scrollHeight > textRef.current.clientHeight
      )
    }
  }, [])

  return (
    <div id={customId} className={`${styles.cartServiceItem}`}>
      {thumbnail && thumbnail.gatsbyImageData ? (
        <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
      ) : (
        <figure>
          <img src={thumbnail?.src || image} alt={title} />
        </figure>
      )}
      <Text
        tag="h3"
        as="h3"
        className="uppercase"
        dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
      ></Text>
      <div className={styles.descWrap}>
        <Text
          tag="div"
          as="subtitleH3"
          className={!isVisibleAll ? 'line-clamp-3' : ''}
        >
          <p
            ref={textRef}
            dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
          ></p>
        </Text>
        {withLineClamp && (
          <span className={styles.more} onClick={handleVisibleAll}>
            <span></span>
            {!isVisibleAll && <span></span>}
          </span>
        )}
      </div>
      {ctaTitle && (
        <KLink
          to={ctaLink.url}
          type={ctaLink.type}
          title={ctaTitle}
          className="text-white inline-flex"
          label={ctaTitle}
        />
      )}
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import SwiperClass from 'swiper'
import { useInView } from 'react-intersection-observer'
import Slider from '../../Core/Slider'
import Tab from '../../Core/Tab'
import Text from '../../Core/Text'
import * as styles from './cartService.module.scss'
import CartServiceItem, { CartServiceItemProps } from './CartServiceItem'
import KLink from "../../Core/KLink";
import replaceHife from "../../../utils/replaceHife";

interface CartServiceProps {
  data: {
    contentTypeAlias: string
    title: string
    subtitle: string
    ctaLink: Object
    cTATitle: string
    itemsPaidServices: Array<CartServiceItemProps>
    serviceCta: string
    formCta: string
  },
  layout?: string
}

export default function CartService(props: CartServiceProps) {
  const {
    data: { title, subtitle, itemsPaidServices = [], contentTypeAlias, serviceCta, formCta },
    layout = 'default',
  } = props

  const [swiperSlider, setSwiperSlider] = useState<SwiperClass>()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const onSliderInit = (initializedSlider: SwiperClass) => {
    if (initializedSlider) setSwiperSlider(initializedSlider)
  }

  const handleChangeTab = (index: number) => {
    swiperSlider?.slideTo(index)
  }

  const handleGoToPaid = () => {
    const $paidService = document.getElementById('blockPaidServices')
    window.scrollTo({
      top: $paidService?.offsetTop,
      behavior: 'smooth',
    })
  }
  const handleGoToLogement = () => {
    const $logementService = document.getElementById('blockAccomodations')
    window.scrollTo({
      top: $logementService?.offsetTop,
      behavior: 'smooth',
    })
  }

  useEffect(()=>{
      const updateDynamicHeight = () => {
        const element = document.getElementById('second-child');
        if (element) {
          const imageWrapper = element.querySelector('.gatsby-image-wrapper');
          const cartServiceElement = document.querySelector('[class^="cartService-module--cartServiceList"]');
          if (imageWrapper && cartServiceElement) {
            const elementHeight = imageWrapper.getBoundingClientRect().height;
            document.documentElement.style.setProperty('--dynamic-height', `${elementHeight}px`);
          }
        }
      };
      updateDynamicHeight();
      window.addEventListener('resize', updateDynamicHeight);
      return () => {
        window.removeEventListener('resize', updateDynamicHeight);
      };
  }, [])

  return (
    <section
      ref={ref}
      id={contentTypeAlias}
      className={`${inView ? 'in-view' : ''}`}
    >
      <div className={styles.cartService}>
        <div className="container">
          <div className={layout === 'landing' ? 'lg:flex lg:justify-between mb-6 lg:mb-20' : ''}>
            <Text tag="h2" as="h2" className="cart-service-title">
              {title}
            </Text>
            { layout === 'landing' && (
                <div className={'lg:w-[41%]'}>
                  {subtitle && (
                      <Text tag="div" as="subtitleH2" className="mt-4" dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}>
                      </Text>
                  )}
                  { (serviceCta || formCta) && (
                    <div className={'mt-6'}>
                      { serviceCta && (
                          <KLink
                              label={serviceCta}
                              btnType="dark"
                              tag="button"
                              onClick={handleGoToPaid}
                              className={'mr-10'}
                          />
                      )}
                    </div>
                  )}
                </div>
            )}
          </div>
          <div className={styles.cartServiceList}>
            <Tab
              goTo={currentSlideIndex}
              uniqId="cartservice"
              mode="scrollNext"
              onChangeTab={handleChangeTab}
              dataSource={itemsPaidServices.map((item, index) => ({
                title: item.title,
                content: (
                  <Slider
                    key={`t-${index}`}
                    slidesPerView={1}
                    className={'!pl-0 !ml-0'}
                    navigation={true}
                    spaceBetween={24}
                    onInit={onSliderInit}
                    autoHeight={false}
                    changeOnSmallHeight
                    onSlideChange={({ realIndex }) => {
                      setCurrentSlideIndex(realIndex)
                    }}
                    breakpoints={{
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 16,
                      },
                    }}
                    data={itemsPaidServices.map((item, index) => ({
                      content: (
                        <CartServiceItem {...item} key={`item-${index}`} customId={`${index == 1 ? 'second-child': ''}`}/>
                      ),
                    }))}
                  />
                ),
              }))}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
